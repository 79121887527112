import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from './LabelText.mobile.scss';
import classNames from 'classnames';
import { FormattedMessage as Translation } from 'react-intl';
import { getNewSearchResultsTitle } from 'Helpers/listingResultsTitle';
class LabelText extends Component {
    static propTypes = {
        label: PropTypes.string,
        term: PropTypes.string,
        total: PropTypes.number,
        isMXCL: PropTypes.bool,
        itemsMetadata: PropTypes.object,
        marketConfig: PropTypes.object
    }

    getTermInBold() {
        const { label, term } = this.props;
        const startIndex = label.indexOf('$');
        const endIndex = label.indexOf('}');

        return (
            <span>
                {[label.slice(0, startIndex), <b key={ `label-term-${term}` }>&quot;{term}&quot;</b>, label.slice(endIndex + 1)]}
            </span>
        );
    }

    render() {
        const { label, total, isMXCL, itemsMetadata, marketConfig } = this.props;

        const showUnavailableYearTitle = marketConfig?.get?.('listing', 'showUnavailableYearTitle');

        const showNewSearchResultsTitle = !!getNewSearchResultsTitle(itemsMetadata, showUnavailableYearTitle);

        if (!showNewSearchResultsTitle && label) {
            return (
                <p className={ classNames(css.label, {
                    [css.mxLabel]: isMXCL
                }) }>
                    <span className={ css.labelText }>{this.getTermInBold()}</span>
                    <span className={ classNames(css.adCount, {
                        [css.mxAdCount]: isMXCL
                    }) }>{total}&nbsp;<Translation id="adsCountPrefix" /></span>
                </p>
            );
        }
        return null;
    }
}

export default LabelText;
